import React,{Component} from 'react';
import Slider from "react-slick";
import grid1 from './../../images/testimonials/pic3.jpg';


const latestBlog = [
	{ image: grid1,	 },
]
class TestiMonialSlider extends Component{	
	constructor(props) {
		super(props);
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
	}
	next() {
		this.slider.slickNext();
	}
	previous() {
		this.slider.slickPrev();
	}
	render(){
		var settings = {		
			arrows: false,
            slidesToShow: 1,			
            infinite: true,
			autoplay: true,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
				  }
				}
			]
        };
		return(
			<>	
				<div className="sprite-nav">
					<Slider className="client-carousel owl-carousel owl-theme  " ref={c => (this.slider = c)} {...settings}>
						{latestBlog.map((item, index) => (
							<div className="item" key={index}>
								<div className="client-box">
									<div className="testimonial-detail clearfix">
										<div className="testimonial-pic radius">
											<img src={item.image} width="100" height="100" alt="" />
										</div>
										<h5 className="testimonial-name m-t0 m-b5"></h5> 
										<span>Parent</span> 
									</div>
									<div className="testimonial-text">
										<p>As a parent, I am content with the school Kalapa Dharsha. I find my child to be enjoying his time at school. Teachers take utmost care to every child and build a good rapport. I see my child's activity as a reflection of what he does at school, like taking care of his belongings, tiding the room, helping in kitchen and so on. Ever child is given an opportunity to participate in the events organised by the school and classroom activities.
											Thank to Management and the lovely teachers.</p>
									</div>
								</div>	
							</div>
						))}	
						</Slider>
					<div className="owl-nav" style={{ textAlign: "center" }}>
						<div className=" owl-prev " onClick={this.previous}/>
						<div className="owl-next " onClick={this.next}/>
					</div>
				</div>	
			</>	
		)
	}
}
export default TestiMonialSlider;