import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import PageTitle from '../Layout/PageTitle';
import bnr1 from './../../images/line2.png';
import team1 from './../../images/team/pic9.jpg';
import team2 from './../../images/team/pic10.jpg';
import team3 from './../../images/team/pic11.jpg';
import team4 from './../../images/team/pic12.jpg';
import team5 from './../../images/team/pic13.jpg';
import team6 from './../../images/team/pic14.jpg';

const teamBlog = [
	{image: team1 ,}, {image: team2 ,}, {image: team3 ,},
	{ image: team4, }, { image: team5, }, { image: team6, },
];

class Teachers extends Component{
	render(){
		return(
			<Fragment>
				<Header />
					<div className="page-content">
						<PageTitle motherMenu="Teachers" activeMenu="Teachers" />
						<div className="content-block">
							<div className="section-full bg-white content-inner" style={{backgroundImage:"url()", backgroundSize:"contain", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
								<div className="container">
									<div className="section-head text-center">
										<h2 className="head-title text-secondry">Teachers</h2>
								</div>
								<div className=" text-center mb-2">
									<p>Teachers are the most important link between the parent and the child especially during early childhood. Our teachers, facilitators and support staff are our pillars of strength. We hold pride in our well trained, Montessori Certified, loving and caring academic staffs who love what they do. We promise your child’s learning journey to be full of smiles and cherishable memories. </p>
								</div>

									<div className="row">
										{teamBlog.map((data,index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6" key={index}>
												 <div className="dlab-box box-frame1 team-box m-b40">
													<div className="dlab-thum"> 
														<img src={data.image} alt="" />
														<div className="overlay-bx">
															{/* <h5 className="team-title"><Link to={"/teachers-details"}>Jone Doe</Link></h5>
															<span className="team-info">Teachers</span> */}
															{/* <ul className="list-inline">
																<li><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-google-plus"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-instagram"></i></Link></li>
																<li><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
															</ul> */}
														</div>
													</div>
												</div>
											</div>
										))}
									</div>	
								</div>
							</div>
						</div>
					</div>
				<Footer />
			</Fragment>
		)
	}
}

export default Teachers;