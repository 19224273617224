import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
import Masonry from "react-masonry-component";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import AccordionBlog from "../Element/AccordionBlog";
import TestiMonialSlider from "../Element/TestiMonialSlider";
import VideoPopup from "../Element/VideoPopup";
//Images
import bnr1 from "./../../images/line2.png";
import bnr2 from "./../../images/background/bg8.jpg";
import bgimg2 from "./../../images/background/bg9.jpg";
import bnr4 from "./../../images/line2.png";
import about from "./../../images/about/about-3.jpg";
import galery1 from "./../../images/gallery/schoolImages/pic9.jpg";
import galery2 from "./../../images/gallery/schoolImages/pic10.jpg";
import galery3 from "./../../images/gallery/schoolImages/pic11.jpg";
import galery4 from "./../../images/gallery/schoolImages/pic12.jpg";
import galery5 from "./../../images/gallery/schoolImages/pic13.jpg";
import galery6 from "./../../images/gallery/schoolImages/pic14.jpg";

// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={(e) => {
        e.preventDefault();
        openLightbox(props.imageToOpen);
      }}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};
const galleryBlog = [
  { image: galery1 },
  { image: galery2 },
  { image: galery3 },
  { image: galery4 },
  { image: galery5 },
  { image: galery6 },
];

function BackgroundBlog() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head text-center">
              <div className="video-play">{/* <VideoPopup /> */}</div>
              <h2>
                “The greatest gifts we can give our children are the Roots of
                Responsibility and the Wings of Independence.”{" "}
              </h2>
              <h4 className="float-right" style={{ color: "white" }}>
                – Dr. Maria Montessori
              </h4>
              {/* <Link to={"./contect-us"} className="btn btn-md radius-xl">Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
function EducationBanner() {
  return (
    <>
      <div
        className="section-full bg-white content-inner-2 about-content bg-img-fix"
        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
      >
        <div className="about-overlay-box"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="section-head">
                <h2 className="head-title text-yellow">
                  Education from birth
                  <br />
                  begins with us
                </h2>
                <p className="text-white">
                  Overall, the statement "Education from birth begins with us"
                  emphasizes the significance of early childhood experiences and
                  the collective responsibility we have in shaping the education
                  and development of the next generation.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12"></div>
          </div>
        </div>
      </div>
    </>
  );
}

class AboutUs1 extends Component {
  componentDidMount() {
    var splitBox = document.querySelectorAll(".split-box");

    var fSB = [].slice.call(splitBox);

    fSB.forEach((el) => el.classList.add("split-active"));
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="About Us" activeMenu="About Us" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner-2 about-area"
              style={{
                backgroundImage: "url()",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <h2 className="head-title text-secondry">
                        About the founder
                      </h2>
                      <p>
                        {" "}
                        <strong>Mrs. Kalpana D Kumar </strong>Founder/ Director
                        of Kare therapy center Correspondent Kalpadhaksha.
                      </p>
                      <p>
                        She is a speech and language pathologist who passed out
                        in 2010 from{" "}
                        <strong>Madras ENT Research Foundation.</strong> She has
                        worked in organizations such as{" "}
                        <strong> Vidya Sagar, Srishti,</strong> and{" "}
                        <strong> MERF </strong> as a consultant speech
                        pathologist,though she was working with children with
                        special needs at therapy centers. <p></p>
                        She always questioned herself as to how these children
                        with alternative abilities can communicate with the
                        outside world, will they have the opportunity to
                        interact with the other typically growing children. Will
                        they be able to express themselves in the way they can
                        and can the regular teachers understand their abilities?
                        This is what motivated her to visit traditional schools
                        and approach them for inclusion.<p></p> She also thought
                        that the child needs professionals who can communicate
                        with the teachers and help them comprehend the different
                        difficulties that a child faces, why learning
                        difficulties occur, and the etymology behind the
                        diagnosis of childhood disorders. She has also done her
                        certification course in learning disability from the{" "}
                        <strong> MADRAS DYSLEXIA ASSOCIATION,</strong> sensory
                        integration certification, and PG diploma in therapeutic
                        play skills from{" "}
                        <strong>
                          (the COLLEGE OF ALLIED EDUCATORS - Singapore).
                        </strong>{" "}
                        <p></p>She strongly believes that all the professionals
                        helping children’s needs must have holistic knowledge
                        and must be active in communicating with the other
                        professionals as we need to learn different
                        methodologies and strategies in helping children, as
                        each child is different.
                      </p>
                    </div>
                    {/* <AccordionBlog /> */}
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 teacher-content">
                    <div className="split-box">
                      <div className="about-media">
                        <img src={about} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <h2 className="head-title text-secondry">
                        Why Montessori?
                      </h2>
                      <p>
                        Montessori set up grants children the{" "}
                        <strong>Freedom to choose </strong> their work, work
                        spaces, work partners and timing. (With freedom comes
                        responsibility. In Montessori environment, children bear
                        responsibility of the consequences of their choices;
                        which in turn instils leadership, problem solving and
                        decision making skills in them.)
                      </p>
                      <p>
                        This set up provides children plenty of opportunities
                        for interaction, community learning and the freedom to
                        work at their own pace. (Dr. Maria Montessori observed
                        that children learnt best in a mixed age environment.
                        They not only learn and grow at their own pace, but also
                        develop uniquely human social experiences.)
                      </p>
                      <p>
                        Montessori materials are a set of unique learning tools,
                        designed with a specific purpose in mind; to cater to
                        the developmental needs in early and primary childhood.
                        (Montessori holds pride in teaching children through
                        uniquely designed study materials, not found in
                        conventional classrooms. Thoughtfully designed, these
                        materials draw children to work, keeping them engaged
                        and motivating them to repeat the learning process.
                        Above all, these are self-correcting!)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr2 + ")" }}
            >
              <BackgroundBlog />
            </div>
            {/*  Portfolio  */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Gallery</h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {galleryBlog.map((data, index) => (
                            <li
                              className="web design card-container col-lg-4 col-md-6 col-sm-6 "
                              key={index}
                            >
                              <div className="dlab-box frame-box m-b30">
                                <div className="dlab-thum dlab-img-overlay1">
                                  <img src={data.image} alt="" />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <Iconimage imageToOpen={index} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
            <EducationBanner />
            {/* <div className="section-full bg-white content-inner-2" style={{backgroundImage:"url(" + bnr4 +")", backgroundSize:"contain",backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">Testimonials</h2>
									
								</div>
								<TestiMonialSlider />
							</div>
						</div> */}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export { BackgroundBlog, EducationBanner };
export default AboutUs1;
