import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox";
import Masonry from "react-masonry-component";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";

//images
import masonary1 from "./../../images/gallery/masonary/pic10.jpg";
import masonary2 from "./../../images/gallery/masonary/pic11.jpg";
import masonary3 from "./../../images/gallery/masonary/pic12.jpg";
import masonary4 from "./../../images/gallery/masonary/pic13.jpg";
import masonary5 from "./../../images/gallery/masonary/pic14.jpg";
import masonary6 from "./../../images/gallery/masonary/pic15.jpg";
import masonary7 from "./../../images/gallery/masonary/pic16.jpg";
import masonary8 from "./../../images/gallery/masonary/pic17.jpg";
import masonary9 from "./../../images/gallery/masonary/pic18.jpg";
import masonary10 from "./../../images/gallery/masonary/pic19.jpg";
import masonary11 from "./../../images/gallery/masonary/pic20.jpg";
import masonary12 from "./../../images/gallery/masonary/pic21.jpg";
import masonary13 from "./../../images/gallery/masonary/pic22.jpg";
import masonary14 from "./../../images/gallery/masonary/pic23.jpg";
// import masonary15 from "./../../images/gallery/masonary/pic24.jpg";
import masonary16 from "./../../images/gallery/masonary/pic25.jpg";
import masonary17 from "./../../images/gallery/masonary/pic26.jpg";
import masonary18 from "./../../images/gallery/masonary/pic27.jpg";
import masonary19 from "./../../images/gallery/masonary/pic28.jpg";
import masonary20 from "./../../images/gallery/masonary/pic29.jpg";
import masonary21 from "./../../images/gallery/masonary/pic30.jpg";
import masonary22 from "./../../images/gallery/masonary/pic31.jpg";
import masonary23 from "./../../images/gallery/masonary/img1.jpg";
import masonary24 from "./../../images/gallery/masonary/img2.jpg";
import masonary25 from "./../../images/gallery/masonary/img3.jpg";
import masonary26 from "./../../images/gallery/masonary/img4.jpg";
import masonary27 from "./../../images/gallery/masonary/img5.jpg";
import masonary28 from "./../../images/gallery/masonary/img6.jpg";
import masonary29 from "./../../images/gallery/masonary/img7.jpg";
import masonary30 from "./../../images/gallery/masonary/img8.jpg";
import masonary31 from "./../../images/gallery/masonary/img9.jpg";
import masonary32 from "./../../images/gallery/masonary/img10.jpg";
import masonary33 from "./../../images/gallery/masonary/img11.jpg";
import masonary34 from "./../../images/gallery/masonary/img12.jpg";
import masonary35 from "./../../images/gallery/masonary/img13.jpg";
import masonary36 from "./../../images/gallery/masonary/img14.jpg";
import masonary37 from "./../../images/gallery/masonary/img15.jpg";
import masonary38 from "./../../images/gallery/masonary/img16.jpg";
import masonary39 from "./../../images/gallery/masonary/img17.jpg";
import masonary40 from "./../../images/gallery/masonary/img18.jpg";
import masonary41 from "./../../images/gallery/masonary/img19.jpg";
import masonary42 from "./../../images/gallery/masonary/img20.jpg";
import masonary43 from "./../../images/gallery/masonary/img21.jpg";
import masonary44 from "./../../images/gallery/masonary/img22.jpg";
import masonary45 from "./../../images/gallery/masonary/img23.jpg";

const galleryBlog = [
  { images: masonary1 },
  { images: masonary2 },
  { images: masonary3 },
  { images: masonary4 },
  { images: masonary5 },
  { images: masonary6 },
  { images: masonary7 },
  { images: masonary8 },
  { images: masonary9 },
  { images: masonary10 },
  { images: masonary11 },
  { images: masonary12 },
  { images: masonary13 },
  { images: masonary14 },
  //   { images: masonary15 },
  { images: masonary16 },
  { images: masonary17 },
  { images: masonary18 },
  { images: masonary19 },
  { images: masonary20 },
  { images: masonary21 },
  { images: masonary22 },
  { images: masonary23 },
  { images: masonary24 },
  { images: masonary25 },
  { images: masonary26 },
  { images: masonary27 },
  { images: masonary28 },
  { images: masonary29 },
  { images: masonary30 },
  { images: masonary31 },
  { images: masonary32 },
  { images: masonary33 },
  { images: masonary34 },
  { images: masonary35 },
  { images: masonary36 },
  { images: masonary37 },
  { images: masonary38 },
  { images: masonary39 },
  { images: masonary40 },
  { images: masonary41 },
  { images: masonary42 },
  { images: masonary43 },
  { images: masonary44 },
  { images: masonary45 },
];
// Masonry section
const masonryOptions = {
  transitionDuration: 0,
};
const imagesLoadedOptions = { background: ".my-bg-image-el" };
// Masonry section end

//Light Gallery Icon
const Iconimage = (props) => {
  const { openLightbox } = useLightbox();
  return (
    <Link
      to={"#"}
      onClick={(e) => {
        e.preventDefault();
        openLightbox(props.imageToOpen);
      }}
      className="check-km"
    >
      <i className="fa fa-search icon-bx-xs"></i>
    </Link>
  );
};

class GalleryMasonary extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Gallery" activeMenu="Gallery" />
          <div className="content-block">
            <div className="section-full content-inner">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Gallery</h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className="clearfix" id="lightgallery">
                      <ul
                        id="masonry"
                        className="dlab-gallery-listing gallery-grid-4 gallery mfp-gallery masonry-gallery"
                      >
                        <Masonry
                          className={"my-gallery-class"}
                          options={masonryOptions}
                          disableImagesLoaded={false}
                          updateOnEachImageLoad={false}
                          imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                          {galleryBlog.map((item, index) => (
                            <li
                              className="web design card-container col-lg-4 col-md-6 col-sm-6 "
                              key={index}
                            >
                              <div className="dlab-box frame-box m-b30">
                                <div className="dlab-thum dlab-img-overlay1 ">
                                  <img src={item.images} alt="" />
                                  <div className="overlay-bx">
                                    <div className="overlay-icon">
                                      <Iconimage imageToOpen={index} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </Masonry>
                      </ul>
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default GalleryMasonary;
