import React, { Fragment, Component } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";






class TearmsOfUse extends Component {
  componentDidMount() {
    var splitBox = document.querySelectorAll(".split-box");

    var fSB = [].slice.call(splitBox);

    fSB.forEach((el) => el.classList.add("split-active"));
  }
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="PRIVACY POLICY" activeMenu="PRIVACY POLICY " />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner-2 about-area"
              style={{
                backgroundImage: "url()",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  
                  <div className="col-lg-12 col-md-12 col-sm-12 m-b15">
                    <div className="section-head">
                      <h3 className="head-title text-secondry">
                        PRIVACY POLICY
                      </h3>
                      <p>The Privacy Policy governs the use of this website.</p>

                      <p><strong>Kalpadhaksha Montessori School</strong> is committed to protect your privacy and works towards offering you a useful, safe online experience.</p>
                      


                      <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given by Indian Public School , to any other institution for any reason whatsoever without your consent.</p>
                      <p><strong>Kalpadhaksha Montessori School</strong> reserves the right, in its discretion, to change or modify all or any part of this Agreement at any time, effective immediately upon notice published on the site. Your continued use of the website constitutes your binding acceptance of these terms and conditions, including any changes or modifications made by <strong>Kalpadhaksha Montessori School</strong> as permitted above.</p>
                      <p><strong>Kalpadhaksha Montessori School</strong> treats your personal information or your use of the service as private and confidential and does not check, edit or reveal it to any third parties except where it believes in good faith, such action is necessary to comply with the applicable legal and regulatory processes or to protect and defend the rights of other users or to enforce the terms of service which are binding on all the users of the site.</p>
                      <p>Except where specifically agreed upon or necessary for operational or regulatory reasons <strong>Kalpadhaksha Montessori School</strong> will not send you any unsolicited information via e-mail. The user can nevertheless unsubscribe from receipt of such e-mails by following instructions therein.</p>
                      <p><strong>Kalpadhaksha Montessori School</strong> may collect unnamed statistics, which do not personally identify you and reserves the right to perform statistical analysis of user behavior and characteristics in order to measure interest in and use of the various areas of the site.</p>
                      <p>The information contents provided on this site cannot be copied, modified, uploaded, downloaded, published or republished, transmitted or otherwise distributed for commercial purposes without prior and explicit permission from the concerned authorities.</p>
                      <p>Reproduction of any information or material provided on this website, with or without any modification, is prohibited unless, with prior permission of <strong>Kalpadhaksha Montessori School</strong> and shall amount to violation of copyright and would be deemed an illegal act.</p>
 
                      <h3 className="head-title text-secondry">
                        REFUND & CANCELLATION POLICY
                      </h3>
                      
                      <p>Fee once paid will not be refunded. However, if there is any excess payment for any reason whatsoever, the student may file his/her claim with the Principal/Accountant, <strong>Kalpadhaksha Montessori School</strong>.</p>
 
                      <h3 className="head-title text-secondry">
                        TERMS & CONDITIONS
                      </h3>
                      
                      <p>1. I/We agree and accept the services provide by <strong>Kalpadhaksha Montessori School</strong> at my/our request for payment of school fees through online mode.</p>

                      <p> 2. I/We have no objection whatsoever, to the Online Fee Payment System providing my/our receipt details to the School.</p>

                      <p> 3. While the School will take all reasonable steps to ensure the accuracy of the payment details, the School is not liable for any not-error. I/We shall not hold the School responsible for any loss, damage, etc. that may be incurred/suffered by me/us if the information contained turns out to be incorrect.</p>

                      <p> 4. I/We agree that any disputes on Payment details will be settled by the online payment system and the School's responsibility is limited to provision of information only.</p>

                      <p>5.I/We agree that we will make fee amount payments as required by the Online Fee Payment System. I/We will not hold the School responsible for the Online Payment System rejects the fee payment amount because of incorrect or incomplete entries.</p>

                      <p>6. I/We agree that the record of the instructions given and transaction with the School shall be conclusive proof and binding for all the purposes and can be used as evidence in any proceeding.</p>

                      <p>7. <strong>Kalpadhaksha Montessori School</strong> does not charge any processing fee or service charge from the students for online payment. However, the students will have to pay the charges as applicable for the merchant banks.</p>
                      <p>8. I/We agree that the School is at liberty to withdraw at any time the Online Fee Payment System, or any services provided there under, in respect of any or all the account(s) without assigning any reason whatsoever, without giving me/us any notice.</p>

                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
           
            
           
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default TearmsOfUse;
