import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import PageTitle from "../Layout/PageTitle";
//images
import bnr1 from "./../../images/line2.png";
import clsimg1 from "./../../images/classes/pic7.jpg";
import clsimg2 from "./../../images/classes/pic8.jpg";
import clsimg3 from "./../../images/classes/pic9.jpg";
import clsimg4 from "./../../images/classes/pic10.jpg";
import clsimg5 from "./../../images/classes/pic11.jpg";
// import clsimg6 from './../../images/classes/pic6.jpg';

const classesBlog = [
  {
    images: clsimg1,
    title: "Daycare",
    age: "1.5 to 3  ",
    time: "08:30 am - 06:00 pm ",
  },
  {
    images: clsimg4,
    title: "Toddlers",
    age: " 2 to 3 ",
    time: "09:00 am - 12:00 pm ",
  },
  {
    images: clsimg3,
    title: "Mont-0 ",
    age: "3 to 4.5  ",
    time: "09:00 am - 12:30 pm ",
  },
  {
    images: clsimg5,
    title: "Mont-1 ",
    age: "4 to 5  ",
    time: "09:00 am - 02:00 pm ",
  },
  {
    images: clsimg2,
    title: "Mont-2 ",
    age: "5 to 7 ",
    time: "09:00 am - 03:00 pm ",
  },
];

class Classes extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content">
          <PageTitle motherMenu="Classes" activeMenu="Classes" />
          <div className="content-block">
            <div
              className="section-full bg-white content-inner"
              style={{
                backgroundImage: "url(" + bnr1 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row sp40">
                  {classesBlog.map((data, index) => (
                    <div className="col-lg-4 col-md-6 col-sm-6" key={index}>
                      <div className="class-item">
                        <div className="class-media">
                          <img src={data.images} alt="" />
                          <p>
                            <span>Class Time: </span>
                            {data.time}
                          </p>
                        </div>
                        <div className="class-info">
                          <h4 style={{ color: "#ed5ab0" }}>{data.title}</h4>
                          <ul className="schedule">
                            <li className="bg-blue class-size">
                              <span>Class Size</span> <span>10 - 20</span>{" "}
                            </li>
                            <li></li>
                            <li className="bg-green years-old">
                              <span>Years Old</span>{" "}
                              <span style={{ textAlign: "center" }}>
                                {data.age}
                              </span>{" "}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
export default Classes;
