import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import BannerSlider from "../Element/BannerSlider";
import FromSlider from "../Element/FromSlider";
import TestiMonialSlider from "../Element/TestiMonialSlider";
import TeacherSlider from "../Element/TeacherSlider";
import GallerySlider from "../Element/GallerySlider";
import { Row, Col } from "reactstrap";

import icon1 from "./../../images/icon/icon1.jpg";
import icon2 from "./../../images/icon/icon2.jpg";
import icon3 from "./../../images/icon/icon3.jpg";
import icon4 from "./../../images/icon/icon4.jpg";
import bgimg1 from "./../../images/line.png";
import bnr3 from "./../../images/background/bg7.jpeg";
import bgimg2 from "./../../images/background/bg1.jpg";
import bgimg3 from "./../../images/line2.png";

const iconBlog = [
  { image: icon1, title1: "To Think Creatively", title2: "and Create" },
  {
    image: icon2,
    title1: "To Feel Fine and to",
    title2: "Understand Emotions",
  },
  { image: icon3, title1: "To be Independent", title2: "and Active" },
  { image: icon4, title1: "To Apply", title2: "Knowledge in Life" },
];
const iconBlog2 = [
  { icon: <i className="flaticon-rattle text-blue" />, title: "Infants" },
  { icon: <i className="flaticon-bricks text-green" />, title: "I myself" },
  { icon: <i className="flaticon-puzzle text-orange" />, title: "Goodie" },
];

class Index1 extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <div className="page-content bg-white">
          {/* Slider Banner */}
          <BannerSlider />
          {/* Slider Banner */}
          <div className="content-block">
            {/*  About Us */}
            <div className="section-full bg-white content-inner-1 text-center">
              <div className="container">
                <div className="section-head">
                  <h2 className="head-title text-secondry">
                    Welcome to Kalpadhaksha group of schools
                  </h2>
                  <p>Gift your child the lifelong Love for Learning!</p>
                </div>
                <div className="section-head">
                  <p>
                    <strong>
                      Kalpadhaksha is a Montessori school, which is for children
                      from 1.5 to 12 years. This was started in the year 2019,
                      with the aim to give quality education along with
                      experiential learning.
                    </strong>
                  </p>
                  <p>
                    <strong>
                      Our motto of the school is “Child is the Curriculum”,
                      which means that our curriculum will be based on the
                      child’s capabilities and learning pace. Every child has
                      his or her own pace to catch up with what is being taught.
                      We respect that and give the child the necessary time to
                      process the information the child is bombarded with.
                    </strong>
                  </p>
                </div>
                {/* <div className="row">
										{iconBlog.map((data, index)=>(
											<div className="col-lg-3 col-md-6 col-sm-6 col-12" key={index}>
												<div className="icon-bx-wraper sr-iconbox m-b20">
													<div className="icon-lg m-b20">
														<Link to={"#"} className="icon-cell"><img src={data.image} alt=""/></Link>
													</div>
													<div className="icon-content">
														<h6 className="dlab-tilte">{data.title1}<br/>{data.title2}</h6>
													</div>
												</div>
											</div>
										))}
									</div> */}
              </div>
            </div>
            {/*  About Us End*/}
            <div
              className="section-full bg-white content-inner-2 about-box"
              style={{
                backgroundImage: "url()",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div className="section-head">
                      <h2 className="head-title text-secondry">Our Mission</h2>
                      <p>
                        Established in 2019, with the aim to provide every child
                        world class Montessori education with experiential
                        learning.
                      </p>
                      <h2 className="head-title text-secondry">Our Motto</h2>
                      <p>
                        <strong>“Child Is The Curriculum” </strong>We “Follow
                        The Child!” While we give complete freedom to our
                        learners to explore, discover, grow and learn at their
                        own pace; we make sure that they meet the right skill
                        developmental milestones at the right time.
                      </p>

                      <Row>
                        <Col>
                          <h2 className="head-title text-secondry">
                            Kalpadaksha Stands Out For
                          </h2>
                          <p>
                            <li>Phonetics training</li>
                            <li>Blended method</li>
                            <li>Play based</li>
                            <li>Fully equipped</li>
                            <li>Live class sessions</li>
                            <li>Integrated curriculum</li>
                            <li>Multi-sport and motor-based activities</li>
                            <li>(Yoga, physical fitness, Zumba)</li>
                            <li>Multiple intelligence lab</li>
                            <li>Child-friendly staff</li>
                            <li>Experiential learning methods</li>
                          </p>
                        </Col>
                        <Col>
                          <h2 className="head-title text-secondry">
                            Montessori Principles
                          </h2>
                          <p>
                            <li>Respect for the Child</li>
                            <li> Well Prepared learning Environment</li>
                            <li>
                              Free Choice along with Freedom within Limits
                            </li>
                            <li>Independence</li>
                            <li>Practical Learning; Play is Work</li>
                            <li>Focus on Self-discipline and Order</li>
                            <li>Observation</li>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/* <div className="col-lg-5 col-md-12 col-sm-12 col-12">
										{iconBlog2.map((item, index)=>(
											<div className="icon-bx-wraper left" key={index}>
												<div className="icon-lg m-b20"> <span className="icon-cell">{item.icon}</span> </div>
												<div className="icon-content">
													<h2 className="dlab-tilte">{item.title}</h2>
													<p>Lectus placerat a ultricies a,interdum donec eget metus auguen u Fusce mollis imperdiet interdum donec eget metus.</p>
												</div>
											</div>
										))}
									</div> */}
                </div>
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-2 about-content bg-img-fix"
              style={{ backgroundImage: "url(" + bnr3 + ")" }}
            >
              <BackgroundBlog2 />
            </div>
            <div className="section-full bg-white content-inner-1">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Gallery</h2>
                  {/* <p>We provide three classes with nine to twenty children each aged twelve months to six years of age.</p> */}
                </div>
                <GallerySlider />
              </div>
            </div>
            <div
              className="section-full bg-white content-inner-1"
              style={{
                backgroundImage: "url(" + bgimg3 + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Teachers</h2>
                  {/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
                </div>
                <TeacherSlider />
              </div>
            </div>
            <div className="section-full bg-white content-inner-1">
              <div className="container">
                <div className="section-head text-center">
                  <h2 className="head-title text-secondry">Testimonials</h2>
                  {/* <p>We have an excellent teacher to child ratio at our Kindergarten to ensure that each child receives the attention he or she needs</p> */}
                </div>
                <TestiMonialSlider />
              </div>
            </div>
            {/* <div className="section-full bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="head-title text-secondry">From the Blog</h2>
									
								</div>
								<FromSlider />
							</div>
						</div> */}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }
}
function BackgroundBlog2() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-12  about-text"
            style={{
              visibility: "visible",
              animationDuration: "2s",
              animationDelay: "0.2s",
              animationName: "fadeIn",
            }}
          >
            <div className="section-head text-center">
              <h4 className="text-white">Join Our New Session</h4>
              <h2>
                Call To Enrol Your Child <br />
                <span className="text-yellow">+91 9884270803</span>
              </h2>
              {/* <Link to={"./contact-us"} className="btn btn-md radius-xl">Read More</Link> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export { BackgroundBlog2 };
export default Index1;
